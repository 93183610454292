





















import './scss/BaseListItemShell.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { namespace } from 'vuex-class';

const NSWorksList = namespace('storeWorkList');

@Component({
    name: 'BaseListItemShell',

    components: {
        'BaseCheckbox': () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    },
})
export default class BaseListItemShell extends Vue {
    @Prop({ default: true }) selectable!: boolean;
    @Prop({ default: false }) selected!: boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: 0 }) index!: number;
    @Prop({ default: false }) draggable!: boolean;
    @Prop({ default: null }) itemId!: string | number | null;

    @NSWorksList.Action(ProjectDocumentsActions.A_SET_DRAGGING_STATE) setDraggingState!: (payload: boolean) => Promise<void>;

    get formattedString() {
        if (this.index < 9) return `0${this.index + 1}`
        return String(this.index + 1);
    }

    emitSelectionChanged() {
        this.$emit('selection-changed');
    }

    onDrag(e) {
        e.dataTransfer.setData('workId', e.target.id);
        this.setDraggingState(true);
    }

    onDragEnd() {
        this.setDraggingState(false);
    }
}
